<template>
  <!-- Navigation -->
  <nav>
    <ul class="nav-aside">
      <li class="nav-aside__item" v-for="child in data.GuiNodeCh" :key="get(child, 'Xnodekey', '')">
        <a href="#" class="nav-aside__item-link" :class="{ 'is-active': isActiveNode(child) }" @click.prevent="onClick(child)">{{
          child.Lanqkey01
        }}</a>
      </li>
    </ul>

    <select @change="onChangePage" v-model="selectedNode" class="nav-aside--select">
      <option v-for="child in data.GuiNodeCh" :value="child" :key="get(child, 'Xnodekey', '')">{{ child.Lanqkey01 }}</option>
    </select>
  </nav>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'WEBMAINCONFIGU_NAV',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      node: {
        GuiNodeCh: []
      },
      selectedNode: {}
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    get,

    init() {
      const GuiNodeCh = get(this.data, 'GuiNodeCh', {});
      const GuiNodeChValues = Object.values(GuiNodeCh);

      if (GuiNodeChValues.length) {
        this.selectedNode = GuiNodeChValues[0];
      }
    },

    isActiveNode(node) {
      const Xnodekey = get(node, 'Xnodekey');
      const selectedNodeXnodekey = get(this.selectedNode, 'Xnodekey');

      return Xnodekey === selectedNodeXnodekey;
    },

    onChangePage() {
      this.$emit('change-page', this.selectedNode);
    },

    onClick(node) {
      console.log('onClick', node);
      this.selectedNode = node;

      this.onChangePage();
    }
  }
};
</script>

<style lang="scss">
.nav-aside {
  margin: 0;
  padding: 0;
  display: none;
  border: 1px solid $c-light-gray;

  @media screen and (min-width: $screen-md) {
    display: block;
  }

  &--select {
    display: none;
    margin-bottom: 1rem;
    width: 100%;

    @media screen and (max-width: $screen-md) {
      display: block;
    }
  }

  &__item {
    border-bottom: 1px solid $c-light-gray;
    list-style: none;

    &:last-child {
      border-bottom: none;
    }

    &-link {
      color: $c-black;
      display: block;
      font-size: 0.9rem;
      padding: 0.7rem 0.9rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: $c-brand;
        color: #ffffff;
      }

      &.is-active {
        background-color: $c-brand;
        color: #ffffff;
      }
    }
  }
}
</style>
